<template>
  <div class="members"></div>
</template>

<script>
import { reloadMixin } from "@/mixins/reloadMixin";

export default {
  name: "Members",
  components: {},
  mixins: [reloadMixin]
};
</script>
